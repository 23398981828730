require('../scss/app.scss');

window.noZensmooth = true;

require('./common/goal.js');
require('./common/js_validation.js');
require('./common/links.js');
require('./common/main.js');
require('./common/pagination.js');
require('./common/respond.js');
require('./common/map.js');
require('./common/need-animation.js');
require('./common/jaliswall.js');

require('./field/range.js');
require('./field/phone.js');
require('./menu/index.js');
require('./header/index.js');
require('./catalogFilter/kitchenFilter.js');
require('./main/index.js');
require('../components/smart-tabs/smart-tabs.js');
require('./stocks/index.js');
require('./reviews/index.js');
require('./faqs/index.js');