import RangeInput from "../field/range";


(function ($) {

    "use strict";

    /**
     * Описание объекта
     */
    let catalogFilter = function () {
        return catalogFilter.init.apply(this, arguments);
    };

    /**
     * Расширение объекта
     */
    $.extend(catalogFilter, {
        /**
         * Настройки по умолчанию
         */
        options: {
            buttonOpen: '[data-catalog-filter-button]',
            wrapperSideBar: '[data-wrapper-sidebar]',
            buttonCloser: '[data-catalog-filter-button-closer]',
            sidebar: '[data-sidebar]',
            from : '[data-filter-kitchen-form]',
            range: '[data-range-input]',
            rangeInputFrom: '[data-range-from-input]',
            rangeInputTo: '[data-range-to-input]',
            rangeInputPriceFrom: '#FilterForm_price_from',
            rangeInputPriceTo: '#FilterForm_price_to',
            catalogContent:  '[data-catalog-kitchen-content]',
            field: '[data-kitchen-field]',
            classFilterOpen: '_open',
            classPressButton: '_press'
        },
        /**
         * Инициализация
         * @param options
         */
        init: function (options) {
            this.options = $.extend(this.options, options);
            this.bind();

            return this;
        },
        /**
         * "Навешиваем" события
         */
        bind: function () {
            let me = this;
            me.rangeInit();
            let form = document.querySelector(me.options.from);

            if (form) {
                let trackedFields = form.querySelectorAll('[data-range-from-input], [data-range-to-input]');
                if (trackedFields) {
                    trackedFields.forEach((field) => {
                        field.addEventListener('change', () => {
                            me.fieldsFilterChange()
                        });
                    });
                }

                let fields =  document.querySelectorAll(me.options.field);
                fields.forEach( (field)=> {
                    field.addEventListener('change', () => {
                        this.updateContent();
                    });
                });

                let buttonOpen = document.querySelector(me.options.buttonOpen);
                buttonOpen.addEventListener('click', () => {
                    this.filterToggle()
                });

                let buttonCloser = document.querySelector(me.options.buttonCloser);
                buttonCloser.addEventListener('click', () => {
                    this.filterToggle()
                });

            }
        },

        filterToggle: function () {
            let sidebar = document.querySelector(this.options.sidebar);
            let button =  document.querySelector(this.options.buttonOpen);
            let wrapperSidebar = document.querySelector(this.options.wrapperSideBar);

            if (sidebar.classList.contains(this.options.classFilterOpen)){
                button.classList.remove(this.options.classPressButton);
                sidebar.classList.remove(this.options.classFilterOpen);

                $(wrapperSidebar).animate({
                    height: "toggle"
                }, 500, function() {
                    // Animation complete.
                });

            } else {

                sidebar.classList.add(this.options.classFilterOpen);
                button.classList.add(this.options.classPressButton);

                $(wrapperSidebar).animate({
                    opacity: 1,
                    height: "toggle"
                }, 500, function() {
                    // Animation complete.
                });
            }
        },

        fieldsFilterChange: function () {
            this.updateContent();
        },

        updateContent: function () {
            let me = this;
            //let $form = $(me.options.form);
            let $form = $('[data-filter-kitchen-form]');
            console.log($form);

            $(me.options.catalogContent).addClass("load");
            $.ajax({
                data: $form.serialize(),
                type: "get",
                success: function (page) {
                    let $page = $(page);
                    let $newContent = $page.find(me.options.catalogContent);
                    let $oldContent = $(me.options.catalogContent);
                    $oldContent.html($newContent.html());
                }
            });
            $(me.options.catalogContent).removeClass('onload');
        },

        rangeInit: function () {
            let form = document.querySelector(this.options.from);
            if (form) {
                let rangeInputs = form.querySelectorAll(this.options.range);
                if (rangeInputs) {
                    rangeInputs.forEach((rangeInput) => {
                        let range = new RangeInput({
                            element: rangeInput,
                            step: parseInt(rangeInput.dataset.step),
                            min: parseInt(rangeInput.dataset.min),
                            max: parseInt(rangeInput.dataset.max),
                        })
                    });
                }
            }
        },
    });

    /**
     * Инициализация функции объекта для jQuery
     */
    return $.catalogFilter = function (options) {
        return catalogFilter.init(options);
    };

})($);

$(function () {
    $.catalogFilter();
});

jQuery.expr[":"].Contains = jQuery.expr.createPseudo(function (arg) {
    return function (elem) {
        return jQuery(elem).text().toUpperCase().indexOf(arg.toUpperCase()) >= 0;
    };
});