/*
JALISWALL 0.1
by Pierre Bonnin - @PierreBonninPRO - 2015
*/
(function($){
  $.fn.jaliswall = function(options){
    this.each(function(){

      let defaults = {
        item : '.wall-item',
        columnClass : '.wall-column',
        resize:false
      }

      let prm = $.extend(defaults, options);

      let container = $(this);
      let items = container.find(prm.item);
      let elemsDatas = [];
      let columns = [];
      let nbCols = getNbCols();

      init();

      function init(){
        nbCols = getNbCols();
        recordAndRemove();
        print();
        if(prm.resize){
          $(window).resize(function(){
            if(nbCols != getNbCols()){
              nbCols = getNbCols();
              setColPos();
              print();
            }
          });
        }
      }

      function getNbCols(){
        let instanceForCompute = false;
        if(container.find(prm.columnClass).length == 0){
          instanceForCompute = true;
          container.append("<div class='"+parseSelector(prm.columnClass)+"'></div>");
        }
        let colWidth = container.find(prm.columnClass).outerWidth(true);
        let wallWidth = container.innerWidth();
        if(instanceForCompute)container.find(prm.columnClass).remove();
        return Math.round(wallWidth / colWidth);
      }

      // save items content and params and removes originals items
      function recordAndRemove(){
        items.each(function(index){
          let item = $(this);
          elemsDatas.push({
            content : item.html(),
            class : item.attr('class'),
            href : item.attr('href'),
            id : item.attr('id'),
            colid : index%nbCols
          });
          item.remove();
        });
      }

      //determines in which column an item should be
      function setColPos(){
        for (let i in elemsDatas){
          elemsDatas[i].colid = i%nbCols;
        }
      }

      // to get a class name without the selector
      function parseSelector(selector){
        return selector.slice(1, selector.length);
      }

      //write and append html
      function print(){
        let tree = '';
        //creates columns
        for (let i=0; i<nbCols; i++){
          tree += "<div class='"+parseSelector(prm.columnClass)+"'></div>";
        }
        container.html(tree);

        // creates items
        for (let i in elemsDatas){


          let html='';
          let content = (elemsDatas[i].content != undefined)?elemsDatas[i].content:'';
          let href = (elemsDatas[i].href != href)?elemsDatas[i].href:'';
          let classe = (elemsDatas[i].class != undefined)?elemsDatas[i].class:'';
          let id = (elemsDatas[i].id != undefined)?elemsDatas[i].id:'';

          if(elemsDatas[i].href != undefined){
            html += "<a "+getAttr(href,'href')+" "+getAttr(classe,'class')+" "+getAttr(id,'id')+">"+content+"</a>";

          }else{
            html += "<div "+getAttr(classe,'class')+" "+getAttr(id,'id')+">"+content+"</a>";
          }

          if (parseInt(i)+1 > nbCols && nbCols > 1){
            let smallColumnNb = getSmallColumn();

            container.children(prm.columnClass).eq(smallColumnNb).append(html);
          } else {
            container.children(prm.columnClass).eq(i%nbCols).append(html);
          }


        }

      }

      function getSmallColumn() {
        let small = 100000000;
        let smallColumnNb = 0;
        for (let i=0; i < nbCols; i++){

          //console.log(container.children(prm.columnClass).eq(i).find(prm.item).last().offset().top);
          let offsetTop = container.children(prm.columnClass).eq(i).height();

          if (offsetTop < small) {
            small = offsetTop;
            smallColumnNb =  i;
          }
        }
        // console.log('итог');
        // console.log(small);
         //console.log(smallColumnNb);
        return smallColumnNb
      }

      //creates a well-formed attribute
      function getAttr(attr, type){
        return (attr != undefined)?type+"='"+attr+"'":'';
      }

    });

    return this;
  }
})(jQuery);
