const burger = document.querySelector('[data-hamburger]');
const closer = document.querySelector('[data-menu-closer]');
const header = document.querySelector('[data-header]');
const menu = document.querySelector('[data-menu]');
const body = document.querySelector('[data-body]');

let transparent = '_transparent';
let visible = '_visible';
let fixed = '_fixed';
let bodyFix = '_fix';
let open = '_open';
let buttonPress =  'button_press';
let bigTop =  '_big-top';

if (burger) {
  burger.addEventListener ("click",(e) => {
    e.preventDefault();
    if (!header.classList.contains('_open')) {
      openMenu();
    } else {
      closeMenu();
    }
  });
}

if (closer) {
  closer.addEventListener ("click",(e) => {
    e.preventDefault();
    if (!header.classList.contains('_open')) {
      openMenu();
    } else {
      closeMenu();
    }
  });
}

function openMenu() {
  if (!header.classList.contains(open)){
    header.classList.add(open);
  }
  if (header.classList.contains(transparent)){
    header.classList.remove(transparent);

    if (!menu.classList.contains(bigTop)) {
      menu.classList.add(bigTop);
    }
  }
  if (!burger.classList.contains(buttonPress)){
    burger.classList.add(buttonPress);
  }
  if (!body.classList.contains(bodyFix)){
    body.classList.add(bodyFix);
  }

  setTimeout(() => {
    if (!menu.classList.contains(open)){
      menu.classList.add(open);
    }
  },200);
  if (!menu.classList.contains(visible)){
    menu.classList.add(visible);
  }

}
function closeMenu() {

  if (body.classList.contains(bodyFix)) {
    body.classList.remove(bodyFix);
  }
  if (menu.classList.contains(visible)) {
    menu.classList.remove(visible);
  }
  setTimeout( () => {
    if (menu.classList.contains(open)) {
      menu.classList.remove(open);

      if (menu.classList.contains(bigTop)) {
        menu.classList.remove(bigTop);
      }
    }
    if (header.classList.contains(open)) {
      header.classList.remove(open);
    }
  },200);

  if (burger.classList.contains(buttonPress)) {
    burger.classList.remove(buttonPress);
  }

  if (!header.classList.contains(fixed)) {
    header.classList.add('_transparent');
  }
}

window.addEventListener ("resize",(e) => {
  e.preventDefault();
  if (window.innerWidth > 1024) {
    closeMenu();
  }
});