import {tns} from 'tiny-slider/src/tiny-slider';

$(function() {
  setTimeout(function () {
    $('.faqs__jaliswall').jaliswall({
      item :'.faq-preview',
      columnClass :'.faqs__jaliswall-column',
      resize: true
    });
  },200);
});

document.addEventListener('DOMContentLoaded', function () {
  $(function () {
    setTimeout(function () {
      let faqSlider = document.querySelector('[data-faqs-slider]');

      if (faqSlider) {
        if (faqSlider.childNodes.length > 1) {
          let slider = tns({
            container: faqSlider,
            items: 1,
            speed: 1000,
            nav: true,
            autoplay: false,
            autoplayButtonOutput: false,
            rewind: true,
            autoHeight: true,
            gutter: 40,
            edgePadding: 20,
            prevButton: '[data-faqs-slider-prev]',
            nextButton: '[data-faqs-slider-next]',
          });
        }
      }
    }, 200);
  });
});