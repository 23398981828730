function initMapYandex() {
  let mapElement = document.querySelector('[data-map]');

  if (mapElement) {
    let myMap = new ymaps.Map(mapElement, {
      center: [parseFloat(mapElement.dataset.lat), parseFloat(mapElement.dataset.lng)],
      zoom: mapElement.dataset.zoom,
      controls: ['zoomControl', 'fullscreenControl']
    }, {
      searchControlProvider: 'yandex#search'
    });

    let myPlacemark = new ymaps.Placemark(myMap.getCenter(), {}, {
      // Опции.
      // Необходимо указать данный тип макета.
      iconLayout: 'default#image',
      // Своё изображение иконки метки.
      iconImageHref: mapElement.dataset.mark,
      // Размеры метки.
      iconImageSize: [133, 150.5],
      // Смещение левого верхнего угла иконки относительно
      // её "ножки" (точки привязки).
      iconImageOffset: [-66.5, -114.5]
    });

    myMap.behaviors.disable('scrollZoom');
    if (window.innerWidth <= 1024) {
      myMap.behaviors.disable('drag');
    }
    myMap.geoObjects.add(myPlacemark);
  }
}

window.addEventListener('load', () => {
  if (window.ymaps) {
    ymaps.ready(initMapYandex);
  }
});