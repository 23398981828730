import {tns} from 'tiny-slider/src/tiny-slider';
import css from 'tiny-slider/dist/tiny-slider.css'

document.addEventListener('DOMContentLoaded',function () {
    $(function () {
        setTimeout(function () {
            let offerSlider = document.querySelector('[data-offer-slider]');
            let advantagesSlider = document.querySelector('[data-advantages-slider]');

            if (offerSlider) {
                if (offerSlider.childNodes.length > 1) {
                    let slider = tns({
                        container: offerSlider,
                        items: 1,
                        responsive: {
                            1025: {
                                speed: 1500,
                            },
                        },
                        autoplay: true,
                        autoplayButtonOutput: false,
                        autoHeight: false,
                        rewind: true,
                        prevButton: '[data-slider-offer-prev]',
                        nextButton: '[data-slider-offer-next]',
                    });
                }
            }

            if (advantagesSlider) {
                if (advantagesSlider.childNodes.length > 4) {
                    let slider = tns({
                        container: advantagesSlider,
                        items: 1,
                        nav: false,
                        mouseDrag: true,
                        swipeAngle: false,
                        rewind: true,
                        gutter: 8,
                        edgePadding: 20,
                        responsive: {
                            577: {
                                items: 2,
                                gutter: 16,
                            },
                            769: {
                                edgePadding: 30,
                            },
                            1025: {
                                items: 4,
                                gutter: 24,
                                edgePadding: 0,
                            },
                        },
                        prevButton: '[data-slider-advantages-prev]',
                        nextButton: '[data-slider-advantages-next]',
                    });
                }
            }
        }, 200);
    });
});

// Get a reference to an element
let process = document.querySelector('data-process-swipe');

if (process) {
    // Create a manager to manager the element
    let manager = new Hammer.Manager(process);

// Create a recognizer
    let Swipe = new Hammer.Swipe();

// Add the recognizer to the manager
    manager.add(Swipe);

// Declare global variables to swiped correct distance
    let deltaX = 0;
    let deltaY = 0;

// Subscribe to a desired event
    manager.on('swipe', function(e) {
        deltaX = deltaX + e.deltaX;
        var direction = e.offsetDirection;
        var translate3d = 'translate3d(' + deltaX + 'px, 0, 0)';

        if (direction === 4 || direction === 2) {
            e.target.innerText = deltaX;
            e.target.style.transform = translate3d;
        }
    });

}

