import noUiSlider from "nouislider";
import wNumb from "wnumb";
import _ from "lodash";
import css from "nouislider/distribute/nouislider.css";

export default class RangeInput {
  constructor(options) {
    this.options = {
      step: 1,
      min: 0,
      max: 0,
      postfix: '',
      from: 0,
      to: 0,
      fromInput: undefined,
      toInput: undefined,
      line: undefined,
      slider: undefined,
      element: undefined,
    };
    this.elements = {
      fromInput: '[data-range-from-input]',
      toInput: '[data-range-to-input]',
      line: '[data-range-line]',
    };
    this.options = Object.assign({}, this.options, options);
    this.init();
  }

  init() {
    this.options.fromInput = this.options.element.querySelector(this.elements.fromInput);
    this.options.toInput = this.options.element.querySelector(this.elements.toInput);
    this.options.line = this.options.element.querySelector(this.elements.line);
    this.bind();
  }

  bind() {

    this.options.slider = noUiSlider.create(this.options.line, {
      step: this.options.step,
      range: {
        'min': this.options.min ? this.options.min : 0,
        'max': this.options.max ? this.options.max : 999999999,
      },
      connect: true,
      behaviour: 'tap-drag',
      tooltips: true,
      start: [ this.options.min, this.options.max ],
      format: wNumb({
        decimals: 0,
        thousand: '',
        suffix: this.options.postfix,
      })
    });

    this.options.slider.on('update', (values) => {
      this.options.fromInput.value = values[0];
      this.options.toInput.value = values[1];
    });
    let changedInputs = _.debounce(() => {
      this.options.fromInput.dispatchEvent(new Event('change'));
    }, 200);
    this.options.slider.on('set', changedInputs);
    let setter = _.debounce(() => {
      this.options.slider.set([
        this.options.fromInput.value,
        this.options.toInput.value
      ]);

    }, 500);
    this.options.fromInput.addEventListener('keyup', setter);
    this.options.toInput.addEventListener('keyup', setter);
  }
}