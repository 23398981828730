
const header = document.querySelector('[data-header]');

if (header) {
  scrollDetected(header);
}

function scrollDetected(header) {
  let tempScrollTop, currentScrollTop = 0;
  let transparent = '_transparent';
  let visible = '_visible';
  let fixed = '_fixed';

  document.addEventListener("scroll", function () {
    currentScrollTop = window.pageYOffset;

    //листаю вниз
    if (tempScrollTop < currentScrollTop) {
      if (window.pageYOffset > 400 ) {

          if (header.classList.contains(visible)){
            header.classList.remove(visible);
          }
        setTimeout( () => {
          if (header.classList.contains(transparent)){
            header.classList.remove(transparent);
          }
          if (!header.classList.contains(fixed)){
            header.classList.add(fixed);
          }
        },200);
      }
    }

    //листаю вверх
    if (tempScrollTop > currentScrollTop) {

      if (window.pageYOffset > 500){
        if (!header.classList.contains(fixed)){
          header.classList.add(fixed);
        }
        if (header.classList.contains(transparent)){
          header.classList.remove(transparent);
        }
        if (!header.classList.contains(visible)){
          header.classList.add(visible);
        }
      } else {
        if (header.classList.contains(visible) && !header.classList.contains(transparent)){
          header.classList.remove(visible);
          setTimeout( () => {
            if (!header.classList.contains(transparent)){
              header.classList.add(transparent);
            }
            if (header.classList.contains(fixed)){
              header.classList.remove(fixed);
            }
            if (!header.classList.contains(visible)){
              header.classList.add(visible);
            }
          },200);
        } else {
          if (header.classList.contains(fixed)){
            header.classList.remove(fixed);
          }
          if (!header.classList.contains(transparent)){
            header.classList.add(transparent);
          }
          setTimeout(()=>{
            if (!header.classList.contains(visible)){
              header.classList.add(visible);
            }
          },200);
        }
      }


    }
    tempScrollTop = currentScrollTop;
  });
}